<template>
  <div class="business-manage">
    <div class="header-search">
      <span class="search-item">
        <span class="search-name">企业名称:</span>
        <comInput class="input-width" v-model="companyNameData.value" :data='companyNameData'></comInput>
      </span>
      <span class="search-item">
        <span class="search-name">企业类型:</span>
        <comSelect class="input-width select-status" v-model="companyTypeData.value" :data='companyTypeData'></comSelect>
      </span>
      <span class="search-item">
        <span class="search-name">企业状态:</span>
        <comSelect class="input-width select-status" v-model="statusOptionData.value" :data='statusOptionData'></comSelect>
      </span>
      <span class="search-item">
        <span class="search-name">注册时间:</span>
        <el-date-picker class="date"
          v-model="createdTimeData.value"
          type="daterange"
          align="right"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd">
        </el-date-picker>
      </span>
      <span class="operate-btn">
        <fontButton @click="search">查询</fontButton>
        <fontButton @click="reset">重置</fontButton>
      </span>
    </div>
    <div class="statistic">
      <statisticBar :statisticData="statisticData" />
    </div>
    <div class="table-main">
      <ContentTable :tableData='tableData' tableType='table' @page-change='pageChange'>
        <template slot="operate" slot-scope="data">
          <span @click="goDetail(data.data.row)" class="table-btn">查看</span>
          <span @click="goEdit(data.data.row)" class="table-btn">编辑</span>
          <span v-if="data.data.row.status=='-1'" @click="goVertify(data.data.row, 2, 'active')" class="table-btn">启用</span>
          <span v-if="data.data.row.status=='1'" @click="goVertify(data.data.row, 2, 'vertify')" class="table-btn">审核</span>
          <span v-if="data.data.row.status=='2'" @click="goVertify(data.data.row, -1, 'detactive')" class="table-btn red">禁用</span>
        </template>
      </ContentTable>
    </div>
    <infoDetail :detailData="detailData" :detailPicData="detailPicData" :show.sync="detailShow" :title="'企业详情'"></infoDetail>
    <newAndEdit :formType="formType" :formData="formData" :show.sync="formShow" :title="formTitle"  
          @saveData="newAndEditSave"></newAndEdit>
  </div>
</template>
<script>
import {tableData_business} from "../common/data"
import basePage from "../common/basePage.vue"
import {ajax_get_companyList, ajax_post_companyUpdate, ajax_post_companyUpdateCore} from "../../../api/index"
export default {
  name: 'businessManage',
  mixins: [basePage],
  data () {
    return {
      companyNameData:{
        placeholder: "请输入企业名称",
        value: ""
      },
      companyTypeData:{
        placeholder: "请选择企业类型企业名称",
        value: "",
        options:[
          {label:"全部", value:""},
          {label:"采购方", value:1},
          {label:"供应商", value:2},
        ]
      },
      statusOptionData:{
        placeholder: "请选择企业状态",
        value: "",
        options:[
          {label:"全部", value:""},
          {label:"待审核", value:1},
          {label:"已认证", value:2},
          {label:"已禁用", value:-1},
        ]
      },
      createdTimeData:{
        value:""
      },
      statusData:[
        {name:"全部企业", value:"123", id:"allCount"},
        {name:"待审核", value:1, id:"auditCount"},
        {name:"已认证", value:2, id:"enableCount"},
        {name:"已禁用", value:-1, id:"disableCount"},
      ],
      statisticData:[
        {name:"全部企业", value:"123", id:"allCount"},
        {name:"待审核", value:1, id:"auditCount"},
        {name:"已认证", value:2, id:"enableCount"},
        {name:"已禁用", value:-1, id:"disableCount"},
      ],
      editId:"",
    }
  },
  created(){
    this.pageInit();
  },
  methods:{
    pageInit(){
      this.ajaxListMethods = ajax_get_companyList;
      this.tableData = this.$deepClone(tableData_business);
      this.generateDetailArr();
      this.generateFormArr("business");
      this.ajax_list_get(true)
      this.ajax_get_statistic()
    },
    async ajax_get_statistic(){
      let res = await this.ajax_get_statisticData(1)
      console.log(res,"statistc")
      this.statisticData.forEach(v=>{
        v.value = res[v.id]
      })
    },
    search(){
      this.ajaxGetData.companyName = this.companyNameData.value;
      this.ajaxGetData.companyType = this.companyTypeData.value;
      this.ajaxGetData.status = this.statusOptionData.value;
      if(this.createdTimeData.value && this.createdTimeData.value.length){
        this.ajaxGetData.startTime = this.createdTimeData.value[0] + " 00:00:00";
        this.ajaxGetData.endTime = this.createdTimeData.value[1] + " 23:59:59";
      }else{
        this.ajaxGetData.startTime ="";
        this.ajaxGetData.endTime = '';
      }
      this.ajax_list_get(true)
    },
    handerTableData(list){
      let resList = [];
      list.forEach((element,index)=>{
        //element.companyTypeStr = this.opntionTypeObj[element.companyType] || ""
        element.companyTypeStr = this.companyTypeData.options.find(v=>v.value ==element.companyType).label
        element.statusStr = (this.statusData.find(v=>v.value == element.status) || {name:'未知'}).name
        element.index = (this.pageData.pageNo - 1) * this.pageData.pageSize + index +1
        resList.push(element)
      })
      return resList;
    },
    reset(){
      this.companyNameData.value = "";
      this.companyTypeData.value = "";
      this.createdTimeData.value = "";
      this.statusOptionData.value = "";
    },
    goDetail(data){
      console.log(data)
      this.tableToDetail(data)
      console.log(this.formData)
      this.dealPicData(data)
      this.detailShow = true;
    },
    dealPicData(data){
      const carArr = data.carUrl.split(",")
      const serverUrl =  "https://wrjc.wanrongly.com/"
      this.detailPicData.forEach(v=>{
        if(v.id == "carBackUrl"){
          v.value = carArr[0] ? (serverUrl + carArr[0]) : ""
        }else if(v.id == "carFrontUrl"){
          v.value = carArr[1] ? (serverUrl + carArr[1]) : ""
        }else{
          v.value = serverUrl + data[v.id]
        }
      })
    },
    goVertify(data, status, type){
      const confirmFig = {
        "active": {
          title:"启用",
          submitText:"确定",
          cancelText:"取消",
          info:"确认启用该企业?"
        },
        "detactive":{
          title:"禁用",
          submitText:"确定",
          cancelText:"取消",
          info:"确认禁用该企业?"
        },
        "vertify":{
          title:"禁用",
          submitText:"审核",
          cancelText:"驳回",
          info:"请确认审核结果?"
        }
      }
      this.$Confirm({
        title: confirmFig[type].title,
        submitText: confirmFig[type].submitText,
        cancelText: confirmFig[type].cancelText,
        info: confirmFig[type].info,
      }).then(()=>{
         this.ajaxVertify(data, status)
      }).catch(()=>{
        // this.ajaxVertify(data, status)
      })
    },
    // 审核
    ajaxVertify(data, status){
      const params = {
        companyCode: data.companyCode,
        status: status
      }
      ajax_post_companyUpdate(params).then(res=>{
        if(res.code == 200){
          this.$message.success("操作成功")
          this.ajax_list_get();
        }
      }).catch(err=>{
        console.log("ajax_err:", err)
      })
    },
    goEdit(data){
      this.formInit(false)
      this.tableToForm(data)
      this.editId = data.companyCode
    },
    newAndEditSave(){
      const params = this.formToSubmit()
      params.companyCode = this.editId;
      let saveMethods = ajax_post_companyUpdateCore;
      saveMethods(params).then(res=>{
        if(res.code == 200){
          this.$message.success("操作成功")
          this.formShow = false;
          this.ajax_list_get()
        }
      }).catch(err=>{
        console.log("ajax_err:",err)
      })
    },
    formToSubmit(){
      let obj = {}
      this.formData.forEach(v=>{
        obj[v.id] = v.value
      })
      return obj
    },
  }
}
</script>
<style lang="less" scoped>
.business-manage {
  height: 100%;
  width: 100%;
  font-size: 14px;
  .header-search{
    width: 100%;
    height: 40px;
    text-align: left;
    margin-bottom: 15px;
    display: flex;
    .search-item{
      margin-right: 15px;
      .date{
         width: 210px;
      }
      .search-name{
        margin-right: 10px;
      }
      .input-width{
        width: 130px;
      }
      .select-status{
        width: 70px;
      }
    }
    .operate-btn{

    }
  }
  .table-main{

  }
  
}
</style>

